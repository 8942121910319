import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./../assets/images/OI_logo_WhiteAsset.png"
// import Logo from "./Logo"
import 'aos/dist/aos.css'

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  componentDidMount() {
      const AOS = require('aos');
      this.aos = AOS
      this.aos.init()
  }

  componentDidUpdate() {
      this.aos.refresh()
  }
  
  render() {
    return (
      <footer className="bg-dark">
        <div className="container-fluid">
          
          <div className="d-none d-md-block">
            <div className="row justify-content-center align-items-center py-2">
              <Link className="col" to="/home/">
                <img src={Logo} className="logo" alt="Organic" />
              </Link>
              <div className="col-auto">
                <ul className="nav">
                  <li className="nav-item">
                    <Link activeClassName="active" className="nav-link text-white-80" to="/portfolios/">Portfolios</Link>
                  </li>
                  <li className="nav-item">
                    <Link activeClassName="active" className="nav-link text-white-80" to="/clients/">Clients</Link>
                  </li>
                  <li className="nav-item">
                    <Link activeClassName="active" className="nav-link text-white-80 " to="/get-in-touch/">Get in touch</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="d-md-none">
            <div className="row justify-content-center align-items-center py-6">
              <center>
                <Link className="col" to="/home/">
                  <img src={Logo} className="logo" alt="Organic" />
                </Link>
                <div className="col-auto py-2">
                  <ul className="nav">
                    <li className="nav-item">
                      <Link activeClassName="active" className="nav-link text-white-80" to="/portfolios/">Portfolios</Link>
                    </li>
                    <li className="nav-item">
                      <Link activeClassName="active" className="nav-link text-white-80" to="/clients/">Clients</Link>
                    </li>
                    <li className="nav-item">
                      <Link activeClassName="active" className="nav-link text-white-80 " to="/get-in-touch/">Get in touch</Link>
                    </li>
                  </ul>
                </div>
              </center>
            </div>
  
          </div>

        </div>
      </footer>
);
}
}