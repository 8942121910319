import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
// import Header from "../components/navbar"
import Footer from "../components/footer"
import Contact from "../components/Contact"
import Portfolios from "../components/Portfolios"
import Background from "./../assets/images/half.png"
import SEO from "../components/seo"

const IndexPage = ({
  data: {
    site
  }
}) => {
  const siteTitle = site.siteMetadata.title
  const description = site.siteMetadata.description
  return (
    <Layout >
      <SEO 
        title="Portfolios" 
        metatitle={siteTitle}
        description={description}
        keywords={[`Yato`, `interior design`, `events management`, `communications`, `kigali`, `rwanda`, `PR`, `PR company`, `architecture`, `fittings`, `furnitures`, `high-end`]}
      />

      {/* <Header /> */}
      <Contact />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
