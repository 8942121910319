import React from 'react'
import { Link } from "gatsby"
import Logo from "./../assets/images/OI_logo_WhiteAsset.png"
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade'; 


function Splash() {
  return (
    <div>
      <section className="bg-dark">
        <div className="container-fluid">
          <div className="row align-items-center text-center justify-content-center min-vh-80 overflow-hidden">
            
            <div className="col-md-3 lift">
              <Zoom>
                <div className="overflow-hidden">
                  <a className="card" href="#!">
                    <div>
                      <img className="img-fluid mb-md-5" src="https://dummyimage.com/200x300/fff/000" alt="..." />
                    </div>
                    <div className="card-img-overlay card-img-overlay-hover">
                      <div className="card-body">
                      <p
                        className="mb-0 text-dark">
                        Marketing, Advertising and Creative Communications
                      </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Zoom>

              <Fade bottom big>
                <div className="overflow-hidden">
                  <h2
                    className="h4 mt-4 mb-md-3 text-white">
                    Grit
                  </h2>
                </div>
              </Fade>
            </div>
            <div className="col-md-3 lift">
              <Zoom>
                <div className="overflow-hidden">
                  <a className="card" href="#!">
                    <div>
                      <img className="img-fluid mb-md-5" src="https://dummyimage.com/200x300/fff/000" alt="..." />
                    </div>
                    <div className="card-img-overlay card-img-overlay-hover">
                      <div className="card-body">
                      <p
                        className="mb-0 text-dark">
                        Corporate Events Management
                      </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Zoom>

              <Fade bottom big>
                <div className="overflow-hidden">
                  <h2
                    className="h4 mt-4 mb-md-3 text-white">
                    Yato
                  </h2>
                </div>
              </Fade>
            </div>
            <div className="col-md-3 lift">
              <Zoom>
                <a className="card" href="#!">
                  <div>
                    <img className="img-fluid mb-md-5" src="https://dummyimage.com/200x300/fff/000" alt="..." />
                  </div>
                  <div className="card-img-overlay card-img-overlay-hover">
                    <div className="card-body">
                    <p
                      className="mb-0 text-dark">
                      Creative design, Architectural design
                    </p>
                    </div>
                  </div>
                </a>
              </Zoom>
              {/* <Zoom>
                <img className="img-fluid mb-md-5" src="https://dummyimage.com/200x300/fff/000" />
              </Zoom> */}
              <Fade bottom big>
              <h2
                className="h4 mt-4 mb-md-3 text-white">
                Milliseconds
              </h2>
              </Fade>
            </div>
            
            <div className="col-md-3 lift">
              <Zoom>
                <a className="card" href="#!">
                  <div>
                    <img className="img-fluid mb-md-5" src="https://dummyimage.com/200x300/fff/000" alt="..." />
                  </div>
                  <div className="card-img-overlay card-img-overlay-hover">
                    <div className="card-body">
                    <p
                      className="mb-0 text-dark">
                      Interior Design
                    </p>
                    </div>
                  </div>
                </a>
              </Zoom>

              {/* <Zoom>
                <img className="img-fluid mb-md-5" src="https://dummyimage.com/200x300/fff/000" />
              </Zoom> */}
              <Fade bottom big>
              <h2
                className="h4 mt-4 mb-md-3 text-white">
                Blanc
              </h2>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Splash
